import './includes/jquery-global';


$(document).ready(function() {
	$("input.copy-billing").change(function() {
			if (this.checked) {
				$(".shipping-fields").fadeIn();
			} else {
				$(".shipping-fields").fadeOut();
			}
		});
}); // end ready
